// Importing fullpage.js
import fullpage from 'fullpage.js';
import 'fullpage.js/vendors/scrolloverflow';
import $ from 'jquery';

// When using fullPage extensions replace the previous import
// of fullpage.js for this file
//import fullpage from 'fullpage.js/dist/fullpage.extensions.min';

// Initializing it
const fullPageInstance = new fullpage('#fullpage-wrapper', {
    licenseKey: 'fa3d4d41-c4f3b4b3-m0f0d34r-w00tl00l',
    navigation: true,
    scrollBar: true,
    scrollingSpeed: 1200,
    fitToSection: true,
    fitToSectionDelay: 1000,
    scrollOverflow: true,
    // autoScrolling: false,
    onLeave: function(index, nextIndex, direction) {
        const leavingSection = $(this);

        if (index > 1 && direction == 'down') {
            document.querySelector('#fullpage-wrapper').classList.add('fullpage--direction-' + direction);
        }
    },
});
