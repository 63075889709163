import jQuery from 'jquery';

document.addEventListener("DOMContentLoaded", function() {

    [].forEach.call(document.querySelectorAll('a[href^="#"]'), function (anchor, index) {
        if (anchor.hash.length) {
            const section = document.querySelector(anchor.hash);

            if ( section ) {
                if ( section.classList.contains('is-hidden') ) {
                    section.classList.remove('is-hidden');
                    section.setAttribute('hidden', true);
                }

                anchor.addEventListener('click', function (event) {
                    event.preventDefault();
                    section.classList.toggle('is-open');
                    section.setAttribute('aria-selected', !! section.classList.contains('is-open'));
                    if ( section.classList.contains('is-open') ) {
                        section.removeAttribute('hidden');
                    } else {
                        section.setAttribute('hidden', true);
                    }

                    anchor.style.display = 'none';
                });
            }
        }
    });

});
