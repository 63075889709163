document.addEventListener("DOMContentLoaded", function() {

    [].forEach.call(document.querySelectorAll('[data-toggle]'), function (button, index) {
        const target = document.getElementById( button.dataset.toggle );

        if ( ! target ) {
            return;
        }

        button.addEventListener('click', function (e) {
            target.classList.toggle('is-open');

            if ( target.classList.contains('is-open') ) {
                button.setAttribute('aria-expanded', true);
                document.body.classList.add( 'is-menu-open' );
            } else {
                button.removeAttribute('aria-expanded');
                document.body.classList.remove( 'is-menu-open' );
            }
        });
    });

    [].forEach.call(document.querySelectorAll('[data-close]'), function (button, index) {
        const target = document.getElementById( button.dataset.close );

        
        if ( ! target ) {
            return;
        }
        
        button.addEventListener('click', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            // console.log(`close ${button.dataset.close} clicked`, target);
            target.classList.remove('is-open');
            button.removeAttribute('aria-expanded');
            document.body.classList.remove( 'is-menu-open' );
        });
    });


    // Submenu collapse on mobile

    [].forEach.call(document.querySelectorAll('#main-nav li.menu-item-has-children > a'), function (anchor, index) {
        anchor.addEventListener('click', function (e) {
            const parent = anchor.parentElement;

            if ( window.outerWidth >= 1024 ) {
                return;
            }

            e.preventDefault();

            if ( ! parent.hasAttribute('aria-expanded') ) {
                parent.setAttribute('aria-expanded', true);
            } else {
                parent.removeAttribute('aria-expanded');
            }
        });
    });

});
